import Accordion from "./modules/accordion";
import Tabs from "./modules/tabs";
import MobileMenu from "./modules/mobile-menu";
import Animate from "./modules/animate";
import ReplaceTitle from "./modules/replace-title";

//Accordion
Accordion();

//Tabs
Tabs();

//Mobile menu
MobileMenu();

//Animate
Animate();

//Wrap Title Content
ReplaceTitle();


/**
 * Stop YouTube video from playing after popup is closed
 *
 */
const overlays = document.querySelectorAll('.pum-overlay');
overlays.forEach(function (overlay) {
    overlay.addEventListener('click', function () {
        const iframe = overlay.querySelector('iframe');
        if (iframe) {
            const src = iframe.src;
            iframe.src = '';
            iframe.src = src.replace('?autoplay=1', '');
        }
    });
});

