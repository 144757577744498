const ReplaceTitle = () => {
    const headings = document.querySelectorAll("h1, h2, h3");
    headings.forEach(heading => {
    
        const span = document.createElement("span");
        span.classList.add('heading-title')
    
        while (heading.firstChild) {
            span.appendChild(heading.firstChild);
        }
    
        heading.appendChild(span);
    });
};
export default ReplaceTitle;
